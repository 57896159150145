import LandingPage from '../components/pages/landing/LandingPage';
import PrivacyPage from '../components/pages/privacy/PrivacyPage';
import TestflightRedirect from '../components/pages/testflight-redirect/TestflightRedirect';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';
amplitude.init(`${process.env.REACT_APP_AMPLITUDE_KEY}`);

const App = () => {
  let alert;
  let routes;
  let app;

  routes = (
    <Routes>
      <Route path='/' element={<LandingPage />} />
      <Route path='/privacy' element={<PrivacyPage />} />
      <Route path='/join-me/:code' element={<TestflightRedirect />} />
    </Routes>
  );
  app = (
    <div className='app'>
      <div className='container'>
        {alert}
        {routes}
      </div>
    </div>
  );

  return (
    <Router>
      {app}
    </Router>
  );
}

export default App;