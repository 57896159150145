import React, { Component, useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { track } from '@amplitude/analytics-browser';

function TestflightRedirect() {
  let { code } = useParams();
  
  const eventProperties = {
    invite_code: code,
  };
  track('invite_link_clicked', eventProperties).promise.finally((response) => {
    window.location.href = 'https://testflight.apple.com/join/wyDQCPvb';
  })

  
  return <></>
}

export default TestflightRedirect;