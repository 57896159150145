import React, { Component } from 'react';

class PrivacyPage extends Component {
  render() {
    return (
      <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
        <header className="masthead mb-auto"></header>
        <main role="main" className='innerCover'>
          <div style={{ 'fontWeight': 'bold', 'fontSize': '3em' }}>Thanks for using SKAM!</div>
          <br></br>
          <div>Welcome! SKAM Real Time is a mobile app made by fans for fans. The app is a place to watch SKAM as it was originally intended: watch the show in real time + talk about the show with other fans.</div>
          <br></br>
          <div style={{ 'fontWeight': 'bold', 'fontSize': '2em' }}>Privacy Policy</div>
          <br></br>
          <div>This Privacy Policy explains how we collect, use, store, protect, and share your personal information through our services.</div>
          <br></br>
          <div style={{ 'fontWeight': 'bold', 'fontSize': '2em' }}>Data Collection and Use</div>
          <br></br>
          <div style={{ 'fontWeight': 'bold', 'fontSize': '1em' }}>
          1. Account Data
          </div>
          <div>
When you sign up for an account, you are asked to enter your Apple ID, username, and profile photo. This data is used to identify you and to make it easy for your friends to find your account.
</div>
<br></br>
<div style={{ 'fontWeight': 'bold', 'fontSize': '1em' }}>
2. Contacts Data
</div>
<div>
When you use SKAM Real Time, you have the option to give access to your address book to find friends. We use your contacts in order to identify other users that you may know.
</div>
<div>
You can invite friends to watch with you by sending a text message to install the app. We will never send text messages to your Contacts without your explicit permission. Invites are sent by you, with your explicit permission.
</div>
<br></br>
<div style={{ 'fontWeight': 'bold', 'fontSize': '1em' }}>
3. Device and App Activity Data
</div>
<div>
When you use SKAM Real Time, we automatically gather information about your device (such as the type of device, operating system, IP address, and unique identifiers) and how you interact with the app (such as the actions you take and how long you use the app). We use this information to improve the app, create new features, and keep our systems secure.
</div>
<br></br>
    <div style={{ 'fontWeight': 'bold', 'fontSize': '2em' }}>Data Disclosure</div>
    <br></br>
    <div>
    We do not sell, rent, license, or lease your data to third parties. We do not share it with third parties for advertising. However, we may disclose it to the following third parties:
    </div>
    <br></br>
    <ul>
    <li>Service Providers – such as hosting services, customer support services, and analytics services.</li>
<li>Professional Advisors – such as lawyers and accountants.</li>
<li>Business Transaction Recipients – including counterparties assisting with a merger, acquisition, financing, reorganization, bankruptcy, receivership, dissolution, asset sale, or similar transaction involving our business.</li>
<li>Legal and Safety – government authorities or regulators or other third parties where required by law or legal process or to protect SKAM app, users’, or other third parties’</li>
</ul>
<br></br>
<div style={{ 'fontWeight': 'bold', 'fontSize': '2em' }}>
Data Retention
</div>
<div>
We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax,accounting or other legal requirements). 
</div>
<div>
When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
</div>
<br></br>
<div style={{ 'fontWeight': 'bold', 'fontSize': '2em' }}>
Data Security
</div>
<br></br>
<div>
We use appropriate security measures to prevent your personal information from being lost, used, altered, disclosed, or accessed in an unauthorized way. We cannot guarantee that the internet itself is 100% secure. Although we do our best to protect your personal information, transmission of personal information to and from our app is at your own risk. You should only access the service within a secure environment.
</div>
<br></br>
<div style={{ 'fontWeight': 'bold', 'fontSize': '2em' }}>
    Your Rights
</div>
<br></br>
<div>We provide all users with the following rights:</div>
<ul>
    <li>Know – you can request to know the categories of data we have collected about you, categories of sources from which we collected it, the purposes for which we collected it, and the categories of third parties to whom we disclosed it;
</li>
    <li>Access – you can download the data we have about you, where it is required by law;</li>
    <li>Delete – you can request that we delete the data we have about you, but this right is not absolute. We may need to keep certain information, for example, to comply with our legal requirements;</li>
    <li>Correct – you can ask that we fix any errors or inaccuracies in the data we hold about you;</li>
    <li>Stop processing – you can tell us to stop processing your data;</li>
    <li>Object – you can object to how we process your data; and</li>
    <li>Complain – you can complain to the data protection regulator where you live (if you have one).</li>
</ul>
<div>
You can exercise these rights by emailing us at <a href="mailto:privacy@kino-technologies.com">privacy@kino-technologies.com</a> and providing the username and Apple ID associated with your account in your message. We will not treat you differently if you exercise any of these rights, but we may need additional information to verify your identity.
</div>
<br></br>
<div style={{ 'fontWeight': 'bold', 'fontSize': '2em' }}>
    California Residents
</div>
<br></br>
<div>
If you are a California resident, California law provides you with the following rights with respect to your personal information:
</div>
<ul>
    <li>The right to know what personal information we have collected, used, disclosed and sold about you during the preceding 12 months, as described above in the Section “Data Collection and Use” To submit a request to know, please email <a href="mailto:privacy@kino-technologies.com">privacy@kino-technologies.com</a>. You also may designate an authorized agent to make a request for access on your behalf.</li>
    <li>The right to request that we delete any personal information we have collected about you. To submit a request for deletion, please email <a href="mailto:privacy@kino-technologies.com">privacy@kino-technologies.com</a>. You also may designate an authorized agent to make a request for deletion on your behalf.</li>
</ul>
<br></br>
<div>
When you exercise these rights and submit a request to us, we will verify your identity by asking you to log in to your account if you have one with us. If you do not have an account with us, we may ask for additional information to verify your identity. We also may use a third party verification provider to verify your identity.
</div>
<div>Your exercise of these rights will have no adverse effect on the quality of our goods or services.</div>
<br></br>
<div style={{ 'fontWeight': 'bold', 'fontSize': '2em' }}>
    Children
</div>
<br></br>
<div>
Children under 13 are prohibited from using SKAM Real Time. We do not seek or knowingly collect any data from children under 13. If we learn that we have collected data from children without proper consent, we will delete that data. If you believe we have collected data from a user under 13, or if you are a parent or guardian of a user under 13 who has provided us data, please email us at privacy@kino-technologies.com with information about the account to request that it be deleted.
</div>
<br></br>
<div style={{ 'fontWeight': 'bold', 'fontSize': '2em' }}>
    Changes to this Policy
</div>
<br></br>
<div>
We may need to update this policy to account for changes in our business and applicable laws. If we change this policy, we will post the new version here. If the changes are significant we will notify you through the app.
</div>
<br></br>
<div style={{ 'fontWeight': 'bold', 'fontSize': '2em' }}>
    Contact Us
</div>
<br></br>
<div>Please email us at <a href="mailto:privacy@kino-technologies.com">privacy@kino-technologies.com</a> with any questions.</div>
        </main>
        <footer className="mastfoot mt-auto" style={{ paddingBottom: '10em' }}></footer>
      </div>
    )
  }
}

export default PrivacyPage;