import React, { Component } from 'react';

class LandingPage extends Component {
  render() {
    return (
      <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
        <header className="masthead mb-auto"></header>
        <main role="main" className='innerCover'>
          <div style={{ 'fontWeight': 'bold', 'fontSize': '6em', textAlign: 'center' }}>SKAM TIME MACHINE</div>
        </main>
        <footer className="mastfoot mt-auto" style={{ paddingBottom: '10em' }}></footer>
      </div>
    )
  }
}

export default LandingPage;